<template>
  <section
    el="section"
    :class="{
      collapse: section.collapse,
    }"
  >
    <router-view name="section"></router-view>
  </section>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      section: this.$store.state.main.layout.section,
    };
  },
  methods: {
    checkSubSection(section) {
      return this.section.active == section ? true : false;
    },
  },
  mounted() {
    if (this.$route.params.id != null) {
      this.section.collapse = false;
    }
  },
};
</script>
<style lang="scss" scoped>
[el="section"] {
  width: 400px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
  transition: width 0.2s;
}
@media (max-width: 600px) {
  [el="section"] {
    width: 100vw;
  }
}
[el="section"].collapse {
  width: 0;
}
</style>
