<template>
  <Header>
    <div el="logo">
      <!-- <span
        class="material-icons menu-btn"
        @click="$store.commit('toggleNavState')"
        >menu</span
      > -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        @click="$store.commit('toggleNavState')"
        class="h-6 w-6 menu-btn"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        style="height:32px;"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h8m-8 6h16"
        />
      </svg>
      
    </div>
    <div>
      <Text el="h4">MyCardian</Text>
    </div>
    <div class="options" el="list-column" style="--gap:16px;">
      <!-- <span class="material-icons help-icon">help_outline</span>
                    <span class="material-icons notify-icon">notifications</span> -->
      <div class="dropdown" @click="$store.commit('toggleAccountDropdown')">
        <!-- <img
          v-if="auth.currentUser != null && auth.currentUser.photoURL != null"
          :src="auth.currentUser.photoURL"
          alt="profile"
          class="profileImg"
          loading="lazy"
        />
        <img
          v-else
          src="../../assets/img/ai_profile.jpg"
          alt="profile"
          class="profileImg"
          loading="lazy"
        /> -->
        <img
          src="../../assets/img/AdobeStock_386306155.webp"
          alt="profile"
          class="profileImg"
          loading="lazy"
        />

        <div class="menu" v-if="header.accountDropdown">
          <Text el="bold">{{ auth.currentUser.displayName }}</Text>
          <hr class="none" el style="margin:8px 0;">
            <div @click="$router.push('/dashboard/profile')"><span class="material-icons">account_box</span><Text el="bold">My Profile</Text></div>
            <!-- <div><span class="material-icons">people</span>User Accounts</div> -->
            <div @click="$router.push('/dashboard/settings')"><span class="material-icons">settings</span><Text el="bold">Settings</Text></div>
          <hr class="none" el style="margin:8px 0;" />
          <div @click="$store.commit('signOut')">
            <span class="material-icons">logout</span>
            <Text el="bold">Sign Out</Text>
          </div>
        </div>
      </div>
    </div>
  </Header>
</template>
<script>
import { auth } from "@/firebase";

export default {
  components: {
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      header: this.$store.state.main.layout.header,
      auth: auth,
    };
  },
};
</script>
<style lang="scss" scoped>
[el="header"] {
  --bg-color: transparent;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-gap: 16px;
  align-items: center;
  padding: 0 30px;
  // box-shadow: 0 0 20px hsla(0, 0%, 0%, 0.15);
}
[el="header"] [el="logo"] {
  fill: #fff;
  height: 35px;
  display: grid;
  grid-gap: 16px;
  grid-auto-flow: column;
  align-items: center;
}
[el="header"] > .options {
  align-items: center;
}
[el="header"] [el="logo"] .material-icons,
.help-icon,
.notify-icon {
  color: hsla(0, 0%, 100%, 0.65);
  -webkit-text-fill-color: currentColor;
  transition: background-color 0.2s;
  cursor: pointer;
}
[el="header"] [el="logo"] .material-icons:hover,
.help-icon:hover,
.notify-icon:hover {
  color: hsla(0, 0%, 100%, 1);
}
.menu-btn {
  border-radius: 100px;
  transition: 0.2s;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  color: hsla(0, 0%, 0%, 0.8);
}
.menu-btn:hover {
  background-color: hsla(0, 0%, 0%, 0.8);
  color: hsla(0, 0%, 100%, 1);
  box-shadow: 0 0 0 12px hsla(0, 0%, 0%, 0.8);
}
.profileImg,
.navProfileImg {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  background-color: #f1f1f1;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  // box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.65);
  box-shadow: 0 0 0 2px rgb(0 0 0 / 80%);
  cursor: pointer;
}
.navProfileImg {
  --size: 112px;
  transition: 0.2s;
  box-shadow: 0 0 0 5px rgb(0 0 0 / 80%);
}
[el="aside"].collapse .navProfileImg {
  --size: 32px;
}
.dropdown {
  position: relative;
}
.dropdown .menu {
  position: absolute;
  right: 0;
  background-color: #fff;
  top: 100%;
  z-index: 5;
  min-height: 100px;
  width: 250px;
  box-shadow: 0 0 0 3px hsla(0, 0%, 0%, 0.80);
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dropdown .menu > *:not(.none) {
  padding: 8px 16px;
  cursor: pointer;
  align-items: center;
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  justify-content: start;
  color: var(--color-b80);
}
.dropdown .menu > * .material-icons {
  font-size: 24px;
}
.dropdown .menu > *:not(.none):hover {
  background-color: rgba(0, 0, 0, 0.07);
  color: var(--color-b);
}
</style>
