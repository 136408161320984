<template>
  <div>
    <div id="modal"></div>
  </div>
  <Layout el="ham fixed">
    <Header />
    <Aside />
    <main el="main">
      <router-view v-if="user.blocked == null || user.blocked == false"></router-view>
      <List el="sm" v-else>
        <Text el="h2">Admin Approval Required</Text>
        <Text>Please ask an admin to grant you access.</Text>
      </List>
    </main>
    <Section />
  </Layout>
</template>

<script>
// @ is an alias to /src
// let _ = require("lodash");
import Header from "../components/dashboard/Header.vue";
import Aside from "../components/dashboard/Aside.vue";
import Section from "../components/dashboard/Section.vue";
import { getAuth } from "firebase/auth";

export default {
  components: {
    Header,
    Aside,
    Section,
  },
  data() {
    return {
      // user: this.$store.state.main.user,
    };
  },
  methods: {

  },
  computed: {
    isAdmin(){
      return this.user.permissions.includes('admin');
    },
    user(){
      return this.$store.state.main.user;
    }
  },
  watch: {},
  async mounted() {
    let x = this;
    // x.$store.commit("services/loadList");
    // x.$store.commit("products/loadList");
    x.$store.commit("loadUser");
    x.$store.commit("users/loadList");
  },
};
</script>
<style lang="scss" scoped>
[el~="layout-ham"] {
  --layout-columns: [header-start aside] max-content [main] 1fr [section]
    max-content [header-end];
  --layout-rows: [header] minmax(65px, max-content) [aside main section] 1fr;
}
[el="main"] {
  // --bg-color: var(--color-7);
  border-radius:24px 0 0 0;
  border-left: 5px solid hsla(0, 0%, 0%, 0.8);
  border-top: 5px solid hsla(0, 0%, 0%, 0.8);
  padding:48px;

  background-size: 300px;
  background-image: url(../assets/img/pattern.svg);
  background-blend-mode: soft-light;
  // background-image: url(../assets/img/test.png);
  // background-size: 700px;
  --bg-color: #0090ff0a;
}
[el="main"] > * {
  padding: 32px;
}

@media (max-width: 600px) {
  [el="section"] {
    width: 100vw;
  }
  [el="main"] > * {
    padding: 20px;
  }
}
</style>
